@font-face {
  font-family: "SF Pro Text";
  src: url("../fonts/SFProText-Regular.ttf") format("woff2"), url("../fonts/SFProText-Regular.woff") format("woff"),
    url("../fonts/SFProText-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Georgia";
  src: url("../fonts/Georgia.ttf") format("woff2"), url("../fonts/Georgia.ttf") format("woff"),
    url("../fonts/Georgia.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Brush Script MT";
  src: url("../fonts/BrushScriptMT.ttf") format("woff2"), url("../fonts//BrushScriptMT.ttf") format("woff"),
    url("../fonts//BrushScriptMT.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Garamond";
  src: url("../fonts/Garamond.ttf") format("woff2"), url("../fonts/Garamond.ttf") format("woff"),
    url("../fonts/Garamond.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Arial";
  src: url("../fonts/ArialMT.ttf") format("woff2"), url("../fonts/ArialMT.ttf") format("woff"),
    url("../fonts/ArialMT.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Times New Roman Cyr";
  src: url("../fonts/TimesNRCyrMT.ttf") format("woff2"), url("../fonts/TimesNRCyrMT.ttf") format("woff"),
    url("../fonts/TimesNRCyrMT.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Tahoma";
  src: url("../fonts/Tahoma.ttf") format("woff2"), url("../fonts/Tahoma.ttf") format("woff"),
    url("../fonts/Tahoma.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Verdana";
  src: url("../fonts/verdana.ttf") format("woff2"), url("../fonts/verdana.ttf") format("woff"),
    url("../fonts/verdana.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica";
  src: url("../fonts/Helvetica.ttf") format("woff2"), url("../fonts/Helvetica.ttf") format("woff"),
    url("../fonts/Helvetica.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Trebuchet MS";
  src: url("../fonts/TrebuchetMS.ttf") format("woff2"), url("../fonts/TrebuchetMS.ttf") format("woff"),
    url("../fonts/TrebuchetMS.ttf") format("truetype");
}

body,
html {
  font-family: "SF Pro Text";
}

.fal,
.far {
  font-family: "Font Awesome 5 Pro" !important;
}

.spinner {
  z-index: 99;
  text-align: center;
  color: #5968bf;
}
.fullPageSpinner {
  z-index: 99;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: #5968bf;
}
.scrollHeight {
  height: 300px;
}
.manageShipping > .Polaris-Layout > .Polaris-Layout__Section {
  margin-top: 0px;
}
.manageShipping > .Polaris-Layout > .Polaris-Layout__Section:nth-child(2) > .Polaris-Banner {
  margin-top: 15px;
}
.sheetBody {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.sheetSection {
  align-items: center;
  border-bottom: 1px solid "#DFE3E8";
  display: flex;
  justify-content: space-between;
  padding: 1.6rem;
  width: 100%;
}
.sheetScrollable {
  padding: 1.6rem;
  height: 100%;
}

.schedulePayment {
  margin-bottom: 15px;
}

.schedulePayment .Polaris-Stack__Item--fill {
  flex: 1 0 calc(100% /4);
}

.schedulePayment .Polaris-Button {
  margin-bottom: 1px;
}
.transactionFees {
  margin-bottom: 15px;
}

.transactionFees .Polaris-Stack__Item--fill {
  flex: 1 0 calc(100% /4);
}

.transactionFees .Polaris-Button {
  margin-bottom: 1px;
}

.checkBoxLabel {
  padding-top: 4px;
}
.formWidth125px {
  width: 125px;
}
.formWidth180px {
  width: 180px;
}

.formWidth160px {
  width: 160px;
}

.redColor {
  color: #ff0000;
}
.langSelector {
  margin-right: 8px;
}
.suffixColor {
  color: black !important;
}
.totalCount {
  font-weight: 300 !important;
  margin: 5px;
}

/* added css for stripe subscription UI */
.Polaris-Page {
  padding: 0 0rem;
}

/* package-slip page css */
.Polaris-Page__OrderSlip {
  display: flex;
  justify-content: center;
  padding: 15px;
}
.order-slip-box {
  max-width: 800px;
  width: 100%;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 14px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #000000;
  background: #ffffff;
}
.order-slip-box table {
  table-layout: fixed;
}
.order-slip-box table tbody {
  width: 100%;
  line-height: inherit;
  text-align: left;
}
.order-slip-box table tbody tr {
  background-color: #ffffff;
}
.order-slip-box table td {
  padding: 0px;
  vertical-align: top;
  word-wrap: break-word;
}

.order-slip-box table tr td:nth-child(2),
td:nth-child(3),
td:nth-child(4),
td:nth-child(5) {
  text-align: right;
}

.order-slip-box table tr.top table td {
  padding-bottom: 20px;
}

.order-slip-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
  background-color: #ffffff;
}

.order-slip-box table tr.information table td {
  padding-bottom: 40px;
  background-color: #ffffff;
}

.order-slip-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  font-size: 14px;
  color: #000000;
}

.order-slip-box table tr.details td {
  padding-bottom: 20px;
}

.order-slip-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.order-slip-box table tr.item.last td {
  border-bottom: none;
}

@page {
  size: auto;
  margin: 0mm;
}
.invoice-box {
  max-width: 800px;
  width: 100%;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 14px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #000000;
}
table {
  width: 100%;
}
.invoice-box table tbody {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  font-size: 14px;
  color: #000000;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

/** RTL **/
.rtl {
  direction: rtl;
  font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
}

.rtl table {
  text-align: right;
}

.rtl table tr td:nth-child(2) {
  text-align: left;
}
.wrapper {
  display: flex;
  justify-content: center;
}
.top {
  background: white !important;
}
tr.information > td > table > tbody > tr > td:nth-child(even) {
  background: white;
}
tr.information > td > table > tbody > tr > td:nth-child(odd) {
  background: white;
}
tr.item:nth-child(even) {
  background: white;
}
.hidden {
  width: 310px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}
.brandLogo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 16px;
  max-height: 42px;
  max-width: 200px;
}
.brandTitle {
  margin-top: 10px;
  margin-left: 12px;
  display: block;
  white-space: nowrap;
}
.vendor-list {
  margin-top: 5px;
  margin-left: 12px;
  display: inline-block;
}
.review-vendor-image{ 
  max-width: 500px;
  margin: 0 auto;
}
.fileInput {
  width: 100%;
  height: 36px;
  padding: 6px 12px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 14px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.error .fileInput {
  border: 1px solid red;
}

/* page based css */

.activity_admin .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer,
.activity_operator .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer,
.activity_provider .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer,
.invitationList_admin .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer,
.invitationList_operator .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer,
.invitationList_provider .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer,
.manageShipping_admin .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer,
.manageShipping_operator .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer,
.manageShipping_provider .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer,
.automaticPayoutsList_operator .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer,
.automaticPayoutsList_provider .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer,
.bulkActivity_admin .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer,
.bulkActivity_operator .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer,
.bulkActivity_provider .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer,
.viewActivity_admin .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer,
.viewActivity_operator .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer,
.viewActivity_provider .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer,
.domainList_admin .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer,
.domainList_operator .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer,
.domainList_provider .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer,
.payment_provider .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer,
.ordersList_provider .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  display: none;
}

.heading {
  font-size: 30px;
  font-weight: bold;
  color: #686a74;
  word-wrap: break-all;
  padding: 10px;
  padding-left: 23px;
  margin: 5px;
  flex-grow: 1;
}
/* .Profile-details {
  font-size: 18px;
  font-weight: bold;
  color: #686a74;
  padding: 5px;
  flex-grow: 1;
  padding-left: 30px;
} */
/* .content-text {
  padding-left: 30px;
} */

.custom-wrapper .Polaris-Stack--alignmentLeading{
  flex-wrap: nowrap;
}


.notes {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
div.notes:hover {
  overflow: visible;
}

.wrapbox { width: 60em; white-space: normal; vertical-align: top; display: inline-block; }

.loose { line-break: loose; }

.noData {
  display: flex;
  justify-content: center;
  font-size: large;
}
.vendorNoImage {
  height: 200px;
  width: 200px;
}
.vendorImage {
  height: auto;
  width: 200px;
  margin-top: 17px;
}
.overflow {
  overflow-wrap: break-word;
}

.ellipsis {
  word-break: break-word;
  text-align: justify;
  max-width: 739px;
}

.ellipsisShopifyList {
  word-break: break-word;
  text-align: justify;
  max-width: 643px;
}

.toast .Polaris-Frame {
  display: none;
}
.sheetSection {
  border-bottom: 1px solid;
  border-color: lightgrey;
}

.Polaris-button-minwidth {
  display: flex;
  flex-wrap: nowrap;
}

.Polaris-MediaCard__PrimaryAction .Polaris-Button {
  background: linear-gradient(180deg, #6371c7, #5563c1);
  color: whitesmoke;
}
.variantEllipsis {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* start: timeline */
.timelineWrapper {
  position: relative;
  font-size: 13px;
  overflow: hidden;
}
.timelineWrapper.order-history {
  margin-bottom: 1rem;
}
.order-history .timeline {
  margin: 0 0px 10px;
}
.product_version {
  margin-left: 72px;
}

.pdfToHtml {
  margin: 10px auto;
  max-width: 1140px;
}

.product_version_nochange {
  padding-top: 7px;
}
.order_version_nochange {
  padding-top: 0px;
}
.activity {
  padding-left: 15px;
  overflow: hidden;
}

.timelineWrapper ul li {
  margin-bottom: 0;
}

.timeline {
  margin: 20px 0px 20px 0px;
  font-size: 13px;
  position: relative;
}

.timeline:before {
  content: "";
  position: absolute;
  height: 135%;
  width: 0px;
  margin-top: 2px;
  left: 7px;
  top: 0;
  border: 1px dotted #c2c2c2;
}

.timeline:last-of-type:not(:only-of-type) {
  display: inline;
}

.timeline > ul {
  list-style: none;
}

.timeline .Polaris-List {
  padding-left: 3rem;
}

.timeline:last-child:before {
  content: "";
}

.timelineLink {
  position: absolute;
  right: 20px;
}

.versionList {
  font-size: 13px;
}
.timelineLink button span {
  text-decoration: none;
}
.timelineLink button span:hover {
  text-decoration: underline;
}

.title-list:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #8ac56c;
  border-radius: 100%;
  left: 0;
  z-index: 99;
  box-sizing: content-box;
  margin-top: 2px;
}

.title-list.other:before {
  background-color: #c2c2c2;
}
.more-link {
  display: inline-block;
  padding-left: 4px;
}

.timeline-wrapper ul li {
  margin-bottom: 0;
}

.floatRight {
  float: right;
}

.dashboard {
  position: relative;
}

.check-box {
  padding-left: 22px;
}

.bulk-view-ellipsis {
  width: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bulk-view-ellipsis:hover {
  text-overflow: unset;
  width: auto;
  white-space: unset;
  overflow: unset;
}
/* .action_link > ul {
  list-style-type: none;
}
.action_link {
  margin-left: -21px;
}

.action_link .Polaris-List {
  list-style: outside none;
}
.action_link {
  margin-left: -20px;
  padding: 3px;

} */

/*   GLOBAL :- OVER RIDE POLARIS STYLE  */

select.Polaris-Select__Input {
  padding: 0 1rem;
}

.Polaris-Tabs__Tab:focus,
.Polaris-Tabs__Title:focus {
  box-shadow: none;
  outline: none;
}
/*  GLOBAL CLASSES */

.d-block {
  display: block;
}

.check-box {
  padding-left: 22px;
}

.text-box {
  padding-left: 22px;
  max-width: 200px;
}

.wrap_word {
  width: 190px;
  word-wrap: break-word;
}
.Polaris-TopBar__ContextControl {
  display: block;
}
/* end */

.content-text-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  display: -moz-box;
  -webkit-line-clamp: 1;
  -moz-line-clamp: 1;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  word-break: break-all;
}

.attachment-text-wrap {
  max-width: 80px;
}

div.content-text-wrap:hover {
  overflow: visible;
  -webkit-line-clamp: 20;
  -moz-line-clamp: 20;
}

/* manage customer button */
.manageCustomerButton .Polaris-PageActions {
  margin: unset;
  border-top: unset;
  padding: unset;
}
/* end */

/* manage customer button */
.manageVendorAccessButton .Polaris-PageActions {
  margin: unset;
  margin-top: 10px;
  border-top: unset;
  padding: unset;
}
/* end */

/* manage customer button */
.manageCustomerButton .Polaris-PageActions {
  margin: unset;
  border-top: unset;
  padding: unset;
}
.Polaris-TopBar__ContextControl {
  display: block;
}
/* end */

/* Address */
.addressDate {
  width: 400px;
}
/* end */

/* fulfillment */
.fulfillment_button .Polaris-PageActions {
  margin: unset;
  border-top: unset;
  padding: unset;
}

.radio_option {
  margin-left: 15px;
}

.attachment {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
}

.badge-space {
  margin-top: 10px;
}

.download-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  background-color: #36454f;
  transform: translate(-50%, -50%);
  z-index: 100;
  color: white;
  cursor: pointer;
}

.attachment:hover .download-icon {
  opacity: 1;
}
/* end */

/* manage address */

.wrap_text {
  overflow-wrap: break-word;
}
/* end */

.wrap_sku {
  display: block;
  width: 350px;
  word-wrap: break-word;
}

.wrap_address {
  display: block;
  width: 250px;
  word-wrap: break-word;
}

.Polaris-Button:focus:after,
.Polaris-Button:hover {
  content: none;
  box-shadow: none;
  outline: none;
}

.Polaris-BulkActions__BulkActionButton .Polaris-Button__Content {
  text-transform: capitalize;
}

/******** Right sidebar style ********** 
 */

.Polaris-Layout__Section--secondary button.Polaris-ActionList__Item {
  padding: 0;
}
.Polaris-Layout__Section--secondary button.Polaris-ActionList__Item:hover,
.Polaris-Layout__Section--secondary button.Polaris-ActionList__Item:active:hover,
.Polaris-Layout__Section--secondary button.Polaris-ActionList__Item:focus {
  box-shadow: none;
  background-image: none;
}
.Polaris-Layout__Section--secondary button.Polaris-ActionList__Item:hover .Polaris-ActionList__Text > div > div {
  text-decoration: underline;
}
.Polaris-Layout__Section--secondary .Polaris-ActionList__Content {
  align-items: flex-start;
}
.Polaris-Layout__Section--secondary .Polaris-ActionList__Text {
  font-weight: 600;
  color: #084e8a;
  transition: all 0.3s ease-in-out;
  line-height: 1.3;
}
.Polaris-Layout__Section--secondary .Polaris-TextStyle--variationSubdued {
  font-weight: 400;
  font-size: 1.2rem;
}

.Polaris-Layout__Section--secondary .Polaris-ActionList__Prefix {
  margin: 0.3rem 1.6rem 0 0;
}
.Polaris-Layout__Section--secondary .Polaris-ActionList {
  padding: 0.2rem 0;
  margin-bottom: 0.8rem;
}
.Polaris-Layout__Section--secondary .Polaris-ActionList__Actions {
  padding: 0;
}
.Polaris-Layout__Section--secondary .Polaris-ActionList:last-child {
  margin-bottom: 0;
}
.Polaris-Layout__Section--secondary .Polaris-Button__Text {
  font-size: 1.2rem;
}
h2.Polaris-Heading {
  font-size: 1.5rem;
}

/* help center */
.help_text > span {
  margin-left: -12px;
}

.chatbox-container {
  height: calc(100vh - 300px);
  width: 100%
}

#__talkjs_launcher {
  background-color: #008060;
  left: auto;
  right: 0;
  bottom: 200px;
  width: 30px;
  height: 70px;
  border-radius: 5px;
  background-size: 17px;
  background-position: center 9px;
}
#__talkjs_launcher.open {
  background-size: 13px;
}
#__talkjs_launcher:after {
  content: "Chat";
  transform: rotate(269deg);
  display: block;
  position: absolute;
  bottom: 13px;
}

.__talkjs_popup {
  right: 40px !important;
  bottom: 94px !important;
} 

/* end */

/* manage customer button */
.manage-link .Polaris-PageActions {
  margin: unset;
  border-top: unset;
  padding: unset;
}
/* end */

/* Currency global style */
.flag .Polaris-Thumbnail {
  max-width: 20px;
  box-shadow: none;
  min-width: auto;
  margin-right: 10px;
}
.rate_checkbox > label + label {
  margin-left: 10px;
}
.currency-badge .Polaris-Badge {
  font-size: 10px;
  vertical-align: top;
  line-height: 1.68;
}
/* end */
.toggle-action .Polaris-Card {
  box-shadow: none;
}
.toggle-action .Polaris-Card__Section {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
.toggle-space .Polaris-Card__Section {
  padding-bottom: 0;
}

/* Note - below page-action class only use for reduce bottom space of the polaris page action component. if needed */

.page-action .Polaris-PageActions {
  padding-bottom: 0;
  border: 0;
}
.toggle-action .Polaris-SettingAction {
  align-items: flex-start;
}

.Polaris-Link:hover {
  text-decoration: underline;
}

.Polaris-Layout__Section--secondary .Polaris-ActionList__Image {
  margin-top: 0;
}

.fix-content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}
ul.Polaris-Tabs {
  flex-wrap: nowrap;
}

.page-action .Polaris-PageActions {
  padding-bottom: 0;
  border: 0;
}

#select-date .Polaris-Popover__Pane,
#select-date  .Polaris-PageActions{
  padding: 1.5rem;
}
#select-date .Polaris-Popover__Pane > .Polaris-Stack {
 margin-bottom: 1.5rem;
}
.page-action-wrapper .Polaris-Stack{
  padding-left: 2rem;
  padding-right: 2rem;
}

.status-tag {
  display: inline-block;
}

.tabs-content._operator .Polaris-Collapsible {
  width: 100%;
}

/******** Media Quries ********** 
 */

/* Medium, Large devices */

@media only screen and (min-width: 600px) {
  .custom-wrapper .Polaris-Stack__Item--fill {
    width: calc(100% - 157px);
  }
  .description-list .Polaris-DescriptionList__Term {
    flex-grow: 1;
  }
}

/** Small devices **/

@media only screen and (max-width: 600px) {
  .order-slip-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .order-slip-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

@media print {
  html,
  body {
    height: min-content;
  }

  #divToPrint,
  #divToPrint * {
    visibility: visible;
  }
  #divToPrint {
    position: relative;
    left: 0;
    top: 0;
  }
}
#registerCancelButton {
  min-width: 85.8px;
}
#eyeButton {
  pointer-events: auto;
}
.chat-setting .Polaris-ChoiceList__Choices li:first-child {
   margin-bottom: 1.2rem;
}


/* Onboarding */
.onboarding-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.remove_tag {
  background-color: white;
}
.Polaris-Tag--removable {
  margin-right: 0.8rem;
  border-radius: 0rem;
}

.holidayMode {
  border-top: .1rem solid rgb(225,227,229,1);
  margin-top: 1.5rem;
}

.slot-options {
  padding: 2rem 2rem 0;
}
.availability-description {
  padding: 0 2rem;
}
.quick-reports .Polaris-Card {
  position: relative;
}
.setting-toggle .Polaris-SettingAction__Action {
  display: none;
}
.mt-7 {
  margin-top: 0.7rem;
}
.reduce-layout-leftspace {
  margin-left: -2rem;
}
.reduce-layout-leftspace .Polaris-Subheading{
  text-transform:none;
}
.reduce-layout-leftspace .Polaris-Collapsible{
  padding-left:3rem;
}

.hide-close-button .Polaris-Icon--colorBase svg {
  display: none;
}

.hide-close-button .Polaris-PageActions {
  padding-bottom: 0;
}

.customer_text{
  margin-left: 30px;
  width: 314px;
}
.option-input {
  width: 105px;
}

.preloging_Heading{
  font-weight: bold;
  font-size: 18px;
  color: #000000;
}

/* .Polaris-Button__Text {
  color: white !important;
} */

.flex-container{
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

}

.prelogin_email_button {
  padding: 0rem 1.6rem 1.6rem 1.6rem;
  color: white !important;
}
.prelogin_phone_button{
  padding: 1.6rem;
  color: white !important;
}

#prelogin_button {
  color: white !important;
}

#prelogin_button_disabled {
  background: var(--p-action-primary-disabled) !important;
  color: var(--p-text-disabled) !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.prelogin_card{
  box-shadow: 1px 3px 5px lightgray;
}

.metafield-action-advance-button .Polaris-PageActions {
  width: 680px;
  margin-left: 0px;
}

.vendor-form-label-pageAction .Polaris-PageActions{
  padding-bottom: 0px;
}

.hide-element{
  display: none;
}