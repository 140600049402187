code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
main {
  display: block;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

/**
  * Add the correct font size in all browsers.
  */

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/**
  * Remove the border on images inside links in IE 10.
  */

img {
  border-style: none;
  max-width: 100%;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button {
  cursor: pointer;
  background: transparent;
  border: 0;
}

button,
input {
  overflow: visible;
  line-height: 1;
}

button,
select {
  text-transform: none;
}
select:focus,
button:focus,
button.active {
  outline: 0 !important;
  box-shadow: none !important;
  border: none;
}

/**
  * Correct the inability to style clickable types in iOS and Safari.
  */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
  * Remove the inner border and padding in Firefox.
  */

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
  * Restore the focus styles unset by the previous rule.
  */

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
  * Correct the padding in Firefox.
  */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
  * 1. Correct the text wrapping in Edge and IE.
  * 2. Correct the color inheritance from `fieldset` elements in IE.
  * 3. Remove the padding so developers are not caught out when they zero out
  *    fieldset` elements in all browsers.
  */

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

.stack-scroll .Polaris-FormLayout .Polaris-FormLayout__Item{
  overflow: auto;
  margin-bottom: 10px;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

/**
  * Correct the cursor style of increment and decrement buttons in Chrome.
  */

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
  * 1. Correct the odd appearance in Chrome and Safari.
  * 2. Correct the outline style in Safari.
  */

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/**
  * Remove the inner padding in Chrome and Safari on macOS.
  */

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
  * 1. Correct the inability to style clickable types in iOS and Safari.
  * 2. Change font properties to `inherit` in Safari.
  */

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */

details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/**
  * Add the correct display in IE 10+.
  */

template {
  display: none;
}

/**
  * Add the correct display in IE 10.
  */

[hidden] {
  display: none;
}

/* 
 *  Remove autofill input background color in chrome
 */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

*,
:after,
:before {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

figure {
  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
}

/* Override polaris components style */

.Polaris-Badge {
  text-transform: capitalize;
  border: none;
}
.Polaris-Badge + .Polaris-Badge {
  margin-left: 1.1rem;
}
.Polaris-Badge--statusSuccess {
  background: #bbe5b3;
}
.Polaris-Card {
  border-radius: 0.3rem;
}
.Polaris-Header-Title__TitleWithMetadataWrapper .Polaris-Header-Title {
  font-size: 2.8rem;
}
.Polaris-Icon {
  height: 1.6rem;
  width: 1.6rem;
}
.Polaris-Page__Content,
 .Polaris-TextContainer {
      word-break: break-word;
 }
.Polaris-ResourceItem--selected {
  background: #ebf3ff;
  background-image: none !important;
}
.Polaris-Thumbnail {
  border: 0.1rem solid #c9cccf;
}

.social-icon {
  font-size: 2.5rem;
  color: #000;
}
.Polaris-Tag.Polaris-Tag--clickable:focus:not(:active)::after,
.Polaris-Select__Input:focus ~ .Polaris-Select__Backdrop::after {
  box-shadow: none;
}
.choice-list .Polaris-Choice {
  padding: 0;
}

.choice-list .Polaris-Choice {
  padding: 0;
}

/* 
* Order list resource list
* Note- Remove this code when BRP new design code merge into develop
*/
.order-list .Polaris-ResourceItem__Owned {
  position: absolute;
  z-index: 100;
  left: 20px;
  top: 21px;
}
.order-list .collapsible-card,
.order-list .list-item {
  margin: 0 !important;
  padding-top: 0;
}
.order-list .Polaris-ResourceItem__Container {
  padding: 0;
}
.order-list .list-item,
.order-list .collapsible-card .Polaris-Card__Section {
  padding-left: 5.8rem !important;
}
.list-item.order-lake {
  padding-left: 2rem !important;
}
.order-list .card-footer .Polaris-Card__Section {
  padding-left: 4.1rem !important;
}
.order-list .card-header {
  padding: 0 2rem 0 6rem;
}
.order-list .list-item > .Polaris-Stack > .Polaris-Stack__Item--fill {
  flex-basis: 50% !important;
  width: 50% !important;
}
/* .order-list .card-footer {
  padding: 1.7rem 2rem 1.7rem 4.5rem;
} */
.collapsible-card .card-header .Polaris-Stack__Item:nth-child(2),
.collapsible-card
  .Polaris-ResourceItem__Container
  .Polaris-Card__Section
  > .Polaris-Stack
  > .Polaris-Stack__Item:nth-child(2) {
  flex-basis: 40% !important;
  width: 40% !important;
}
.order-list .ellipsis .Polaris-Caption:last-of-type {
  margin-bottom: 0.7rem;
}
.Polaris-Link {
  text-decoration: none;
}

/* Fixed polaris and talk.js attribute(css) conflict issue */

div[id^="__talkjs_popup_container"][data-lock-scrolling-wrapper] {
  height: auto;
}

/* More filter  */
.Polaris-Tabs__Panel .Polaris-Filters__TagsContainer  > .Polaris-Tag--removable {
  background: #fff;
}

.Polaris-Tabs__Panel .Polaris-Filters__TagsContainer .Polaris-Tag--removable {
  margin-right: 10px;
}

/* Sidebar filter */
.Polaris-Sheet__Container {
  z-index: 1000;
}
.packing-slip-link {
  display: inline-block;
  vertical-align: top;
}

/* Analytics start */

.Polaris-Popover__Wrapper {
  overflow: visible;
}
#calendar-wrapper {
  width: 28rem;
  background: #fff;
  padding: 1.5rem 1.5rem 0;
  box-shadow: 0.2rem 0 1rem 0 rgb(0 0 0 / 10%);
  max-height: none;
}
#calendar-wrapper .Polaris-DatePicker {
  margin-top: 0.7rem;
  overflow: hidden;
}
#calendar-wrapper .Polaris-DatePicker__Day {
  padding: 0.5rem;
}
#calendar-wrapper .Polaris-TextField__Input {
  line-height: 1.7rem;
  max-width: 11.6rem;
  min-height: auto;
  font-size: 1.3rem;
}
.calendar-actions .Polaris-PageActions {
  padding: 1.2rem 0;
}
.calendar-actions .Polaris-Button {
  min-height: auto;
}
.calendar-actions .Polaris-Button__Content {
  font-size: 1.2rem;
}   
/* Analytics Closed */

/* For metafield.js */
.card-discription {
  padding-top: 1rem;
}

.table-input {
  width: 105px;
}

.meta-input{
  width: 110px;
}
.meta-input-standard {
  width: 230px
}
.metafield-card .metafield-action-button .Polaris-PageActions{
  margin-top: 23px;
  margin-left: 0px;
  width: 685px;
  padding-bottom: 0;
  border-top: 0.1rem solid var(--p-divider);
} 
.meta-head {
  display: flex;
  padding-bottom: 20px;
}
.meta-head .meta-title {
  width: 120px;
}
.meta-label {
  position: relative;
  top: -5px;
}

#tidio-chat-iframe {
  height: 170px !important;
}

.metafield-card .Polaris-TextContainer {
  margin-right: 70px;
}
.metafield-card .Polaris-Layout__AnnotationDescription  {
  width: 230px;
}
.metafield-card .Polaris-Card, .metafield-card .Polaris-PageActions {
  width: 725px;
  margin-left: -86px;
}
.metafield_required .Polaris-Choice {
  margin-left: 17px;
  width: 40px;
}
.catalog-label {
  width: 275px;
}
.meta-required-standard {
  width: 72px;
  display: flex;
  justify-content: center;
}
.metafield_delete .Polaris-Button {
  margin-left: 6px;
}
.meta_buttons .Polaris-Stack {
  width: 688px;
}
.meta_buttons .Polaris-PageActions {
  margin-left: 0;
}
.meta_delete--btn .Polaris-Button {
  margin-top: 0;
}

.equal-stack-item  .Polaris-Stack__Item {
  flex: 0 0 20%;
}
.stack-item-5 .Polaris-Stack__Item {
  flex: 0 0 20%;
}
.equal-stack-item   {
  margin: 14px 0px;
}
.stack-item-5 .Polaris-Stack__Item:last-child {
  flex: 0 1 auto;
}

.stackItem-50 .Polaris-Stack__Item{
  flex:0 0 43%;
}
.stackItem-50 .Polaris-Stack__Item:last-child{
  flex: 1 1 auto;
}
.text-order .Polaris-Label__Text {
  font-size: 1.3rem;
}
.Polaris-Modal-Dialog__Modal > .Polaris-Modal-Dialog--sizeLarge {
max-width: 75rem ;
}
.bulk-card .Polaris-Card {
  min-width: -webkit-fill-available;
  width: fit-content;
}
.bulk-form .Polaris-FormLayout--grouped .Polaris-FormLayout__Items {
  flex-wrap: nowrap;
}

/* end  */

